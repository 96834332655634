<template>
  <div>
    <a-modal
      v-model="visibleModal"
      :title="isEdit ? 'Form Edit Passing Grade' : 'Form Create Passing Grade'"
      @cancel="toggleModal()"
      :width="800"
      centered
    >
      <form-passing-grade :listPredicates="listPredicates" :newData="newData" @handle-change="handleChange" :isSubmit="isSubmit" @handle-validate="handleValidate" :visible="visibleModal" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModal()">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModal"
          @click="handleOkModal"
        >
          {{ isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModal()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Passing Grade</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <a-table
          slot="expandedRowRender"
          slot-scope="text"
          :columns="childrenColumns"
          :data-source="text.predicates"
          :pagination="false"
        />
        <div slot="checkmark" slot-scope="value">
          <a-checkbox :checked="value" disabled />
        </div>
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModal(record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDelete(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormPassingGrade = () => import('@/components/app/Admin/Master/Form/PassingGrade')
const columns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    align: 'center',
  },
  {
    title: 'Passing Grade',
    dataIndex: 'nilai',
    key: 'alias',
    align: 'center',
  },
  {
    title: 'Global Passing Grade',
    dataIndex: 'isTeacherNote',
    key: 'global',
    align: 'center',
    scopedSlots: { customRender: 'checkmark' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
const childrenColumns = [
  {
    title: 'Predicate',
    dataIndex: 'predikat',
    key: 'predicate',
    align: 'center',
  },
  {
    title: 'Minimum Grade',
    dataIndex: 'batas_bawah',
    key: 'minimum_grade',
    align: 'center',
  },
  {
    title: 'Maximum Grade',
    dataIndex: 'batas_atas',
    key: 'maximum_grade',
    align: 'center',
  },
]

export default {
  components: { FormPassingGrade },
  data() {
    return {
      pagination: { current: 1 },
      dataTable: [],
      columns,
      childrenColumns,
      loadingTable: false,
      visibleModal: false,
      newData: {
        nama: null,
        nilai: null,
        isTeacherNote: false,
        predicates: [],
      },
      loadingActionModal: false,
      isEdit: false,
      isSubmit: false,
      editRecord: {},
      listPredicates: [],
    }
  },
  methods: {
    toggleModal(data) {
      if (this.listPredicates.length < 2) {
        this.$notification.error({
          message: 'Error.',
          description: "You can't create passing grade. First, create predicate minimum 2.",
        })
      }
      this.visibleModal = !this.visibleModal
      if (!this.visibleModal) {
        this.newData = {
          nama: null,
          nilai: null,
          isTeacherNote: false,
          predicates: [],
        }
        this.isEdit = false
        this.editRecord = {}
      }

      if (data) {
        this.isEdit = true
        const { isTeacherNote, nama, nilai, intervals } = data
        const predicates = intervals.map((interval, i) => {
          return {
            key: i,
            batas_bawah: interval.batas_bawah,
            id_predikat: interval.id_predikat,
            id: interval.id,
          }
        })
        this.newData = { isTeacherNote, nama, nilai, predicates }
        this.editRecord = data
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this passing grade?</div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('master/DELETE_PASSING_GRADE', { id: data.id })
            this.$notification.success({
              message: 'Success',
              description:
                'Passing grade is deleted.',
            })
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Error.',
              description: 'Passing grade cannot be deleted.',
            })
          }
          this.fetchDataTable()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    handleChange(payload) {
      const { value, column } = payload
      this.newData[column] = value
    },
    handleOkModal() {
      this.isSubmit = true
    },
    handleValidate({ valid, predicates, message }) {
      if (valid) {
        this.loadingActionModal = true
        predicates.forEach(pred => {
          const findPred = this.newData.predicates.find(predicate => predicate.id_predikat === pred.id_predikat)
          if (findPred) {
            findPred.batas_bawah = pred.batas_bawah
          } else this.newData.predicates.push(pred)
        })
        this.newData.predicates = this.newData.predicates.sort((a, b) => a.batas_bawah - b.batas_bawah)
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to { this.isEdit ? 'edit this' : 'add new'} passing grade?</div>
          ),
          onOk: async () => {
            const status = this.isEdit ? 'edited' : 'added'
            try {
              const dispatchStatus = this.isEdit ? 'PUT' : 'POST'
              const payloadEdit = this.isEdit ? { id: this.editRecord.id } : {}
              await this.$store.dispatch(`master/${dispatchStatus}_PASSING_GRADE`, { newData: this.newData, ...payloadEdit })
              this.$notification.success({
                message: 'Success',
                description:
                  `Passing grade is ${status}.`,
              })
            } catch (err) {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: `Passing grade cannot be ${status}.`,
              })
            }
            this.fetchDataTable()
            this.toggleModal()
            this.isSubmit = false
            this.loadingActionModal = false
          },
          onCancel: () => {
            this.isSubmit = false
            this.loadingActionModal = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.isEdit ? 'Edit' : 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error.',
          description: message || 'All field is required.',
        })
        this.isSubmit = false
      }
    },
    async fetchDataTable() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PASSING_GRADE', { page: this.pagination.current })
        this.dataTable = data.map(dat => {
          const predicates = dat.intervals.map((int, i) => {
            const batasAtas = i === 0 ? 100 : dat.intervals[i - 1].batas_bawah
            return {
              key: int.id,
              batas_bawah: int.batas_bawah,
              batas_atas: batasAtas,
              predikat: int.predikat.nama,
            }
          })
          return {
            ...dat,
            key: dat.id,
            predicates,
          }
        })
        const total = this.dataTable.length
        this.pagination = { ...this.pagination, total }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataPredicate() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PREDICATE', { page: 'all' })
        this.listPredicates = data
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    this.fetchDataTable()
    this.fetchDataPredicate()
  },
}
</script>

<style>
</style>
